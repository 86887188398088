const DropdownArrowIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1 7.81077L2.81055 6L11.8105 15L20.8105 6L22.6213 7.81077L11.8107 18.6214L1 7.81077Z"
        fill="#262626"
      />
    </svg>
  );
};
export default DropdownArrowIcon;
