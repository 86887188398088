const DashboardIcon = () => {
  return (
    <svg
      width="24"
      height="22"
      viewBox="0 0 24 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Frame 1000002454">
        <g id="Group">
          <path
            id="Vector"
            d="M11.9687 1.00073C11.855 1.00896 11.7474 1.05583 11.664 1.13354L1.16064 10.6332C1.08699 10.7012 1.03551 10.7899 1.01289 10.8875C0.990267 10.9852 0.997543 11.0874 1.03377 11.1809C1.07001 11.2744 1.13352 11.3549 1.21606 11.4118C1.29861 11.4688 1.39638 11.4996 1.49668 11.5003H4.49763V20.5C4.49764 20.6326 4.55034 20.7597 4.64414 20.8535C4.73793 20.9473 4.86514 20.9999 4.99779 21H19.0022C19.1349 20.9999 19.2621 20.9473 19.3559 20.8535C19.4497 20.7597 19.5024 20.6326 19.5024 20.5V11.5003H22.5033C22.6036 11.4996 22.7014 11.4688 22.7839 11.4118C22.8665 11.3549 22.93 11.2744 22.9662 11.1809C23.0025 11.0874 23.0097 10.9852 22.9871 10.8875C22.9645 10.7899 22.913 10.7012 22.8394 10.6332L12.336 1.13354C12.2869 1.08791 12.229 1.05263 12.1659 1.02981C12.1028 1.007 12.0357 0.997107 11.9687 1.00073ZM12 2.17256L21.206 10.5004H19.0022C18.8696 10.5004 18.7424 10.553 18.6486 10.6468C18.5548 10.7406 18.5021 10.8678 18.5021 11.0004V20H14.7509V14.7502C14.7509 13.2354 13.5153 12.0003 12 12.0003C10.4847 12.0003 9.24913 13.2354 9.24913 14.7502V20H5.49794V11.0004C5.49794 10.8678 5.44524 10.7406 5.35144 10.6468C5.25765 10.553 5.13044 10.5004 4.99779 10.5004H2.79396L12 2.17256ZM12 13.0003C12.9785 13.0003 13.7506 13.7721 13.7506 14.7502V20H10.2494V14.7502C10.2494 13.7721 11.0215 13.0003 12 13.0003Z"
            fill="currentColor"
            stroke="currentColor"
            stroke-width="0.4"
          />
        </g>
      </g>
    </svg>
  );
};
export default DashboardIcon;
