const LeftArrowSVG = () => {
  return (
    <svg
      width="23"
      height="25"
      viewBox="0 0 23 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.8026 23.2856L6.25976 12.7428C6.17776 12.6658 6.11241 12.5729 6.06773 12.4697C6.02306 12.3665 6.00001 12.2552 6.00001 12.1428C6.00001 12.0303 6.02306 11.9191 6.06773 11.8159C6.11241 11.7127 6.17776 11.6197 6.25976 11.5428L16.8026 0.999929"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export default LeftArrowSVG;
