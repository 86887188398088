export { default as InstagramIcon } from "./InstagramIcon";
export { default as LeftArrowSVG } from "./LeftArrowSVG";
export { default as PinterestIcon } from "./PinterestIcon";
export { default as RightArrowSVG } from "./RightArrowSVG";
export { default as WhatsAppIcon } from "./WhatsAppIcon";
export { default as OrderABoxIcon } from "./OrderABoxIcon";
export { default as MyBoxesIcon } from "./MyBoxesIcon";
export { default as YourPreferencesIcon } from "./YourPreferencesIcon";
export { default as AccountSettingsIcon } from "./AccountSettingsIcon";
export { default as MyRewardsIcon } from "./MyRewardsIcon";
export { default as FaqsIcon } from "./FaqsIcon";
export { default as LogoutIcon } from "./LogoutIcon";
export { default as DashboardRightArrowSVG } from "./DashboardRightArrowSVG";
export { default as DropdownArrowIcon } from "./DropdownArrowIcon";
export { default as NewDropdownArrowIcon } from "./NewDropdownArrowIcon";
export { default as DashboardIcon } from "./DashboardIcon";
