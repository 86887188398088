import React from "react";

const DashboardRightArrowSVG = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.08337 3.33398L12.7764 9.64168C12.8207 9.68772 12.856 9.74333 12.8801 9.80507C12.9043 9.86681 12.9167 9.93337 12.9167 10.0007C12.9167 10.0679 12.9043 10.1345 12.8801 10.1962C12.856 10.258 12.8207 10.3136 12.7764 10.3596L7.08337 16.6673"
        stroke="currentColor"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export default DashboardRightArrowSVG;
