const WhatsAppIcon = () => {
  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.7584 13.7907C17.7116 13.7683 15.962 12.9067 15.651 12.7948C15.5241 12.7492 15.3881 12.7047 15.2435 12.7047C15.0073 12.7047 14.8088 12.8225 14.6542 13.0537C14.4794 13.3135 13.9503 13.932 13.7868 14.1167C13.7655 14.1411 13.7363 14.1703 13.7189 14.1703C13.7032 14.1703 13.4324 14.0588 13.3505 14.0232C11.4736 13.2079 10.0489 11.2473 9.85357 10.9166C9.82567 10.8691 9.8245 10.8475 9.82427 10.8475C9.83112 10.8224 9.89424 10.7591 9.92681 10.7264C10.0221 10.6321 10.1254 10.5079 10.2253 10.3876C10.2726 10.3307 10.3199 10.2736 10.3664 10.2199C10.5114 10.0512 10.5759 9.92033 10.6507 9.76869L10.6899 9.68991C10.8726 9.32703 10.7166 9.0208 10.6661 8.92191C10.6248 8.83916 9.88598 7.05615 9.80744 6.86882C9.61855 6.4168 9.36897 6.20633 9.02214 6.20633C8.98996 6.20633 9.02214 6.20633 8.88718 6.21202C8.72284 6.21895 7.8279 6.33677 7.43222 6.5862C7.0126 6.85074 6.30273 7.69402 6.30273 9.17703C6.30273 10.5118 7.14975 11.772 7.51341 12.2513C7.52245 12.2634 7.53905 12.2879 7.56312 12.3231C8.95583 14.3571 10.692 15.8644 12.452 16.5675C14.1464 17.2443 14.9488 17.3225 15.405 17.3225C15.405 17.3225 15.405 17.3225 15.405 17.3225C15.5967 17.3225 15.7502 17.3075 15.8855 17.2942L15.9714 17.286C16.5567 17.2341 17.8431 16.5676 18.1357 15.7544C18.3662 15.114 18.4269 14.4143 18.2736 14.1603C18.1686 13.9876 17.9875 13.9007 17.7584 13.7907Z"
        fill="currentColor"
      />
      <path
        d="M12.213 0C5.71307 0 0.424969 5.24836 0.424969 11.6995C0.424969 13.786 0.983358 15.8284 2.04115 17.6159L0.0165014 23.5883C-0.0212129 23.6996 0.00683904 23.8227 0.0892027 23.9066C0.148657 23.9673 0.229307 24 0.31167 24C0.343229 24 0.375021 23.9952 0.406034 23.9854L6.63357 22.0064C8.33772 22.917 10.2638 23.3976 12.2131 23.3976C18.7124 23.3977 24 18.1498 24 11.6995C24 5.24836 18.7124 0 12.213 0ZM12.213 20.9606C10.3788 20.9606 8.60227 20.4309 7.07515 19.4289C7.0238 19.3951 6.96419 19.3778 6.90419 19.3778C6.87248 19.3778 6.84068 19.3826 6.80975 19.3925L3.69014 20.3841L4.6972 17.413C4.72977 17.3169 4.71349 17.2108 4.65349 17.1288C3.49058 15.5398 2.87585 13.6625 2.87585 11.6995C2.87585 6.59221 7.06448 2.43709 12.2129 2.43709C17.3608 2.43709 21.5489 6.59221 21.5489 11.6995C21.549 16.8061 17.3609 20.9606 12.213 20.9606Z"
        fill="currentColor"
      />
    </svg>
  );
};
export default WhatsAppIcon;
