const PinterestIcon = () => {
  return (
    <svg
      width="28px"
      height="28px"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2648_41199)">
        <path
          d="M8.49 19.191C8.514 18.855 8.562 18.52 8.634 18.19C8.697 17.895 8.888 17.06 9.168 15.85L9.175 15.82L9.562 14.152C9.641 13.812 9.702 13.548 9.743 13.46C9.54882 13.0111 9.45202 12.5261 9.459 12.037C9.459 10.7 10.215 9.664 11.195 9.664C11.555 9.658 11.899 9.814 12.137 10.09C12.375 10.365 12.485 10.734 12.439 11.086C12.439 11.539 12.354 11.884 11.986 13.121C11.915 13.359 11.866 13.525 11.82 13.692C11.769 13.88 11.725 14.05 11.688 14.214C11.592 14.6 11.68 15.011 11.925 15.32C12.0439 15.471 12.1973 15.5913 12.3724 15.6706C12.5475 15.75 12.7391 15.7861 12.931 15.776C14.423 15.776 15.531 13.791 15.531 11.228C15.531 9.258 14.241 7.954 12.099 7.954C11.5628 7.93407 11.0283 8.02563 10.5293 8.22289C10.0303 8.42015 9.57764 8.71881 9.2 9.1C8.81426 9.48954 8.50994 9.95197 8.30477 10.4603C8.0996 10.9687 7.9977 11.5128 8.005 12.061C7.98014 12.6518 8.16111 13.2328 8.517 13.705C8.698 13.845 8.767 14.088 8.692 14.295C8.651 14.463 8.552 14.847 8.516 14.975C8.50556 15.0379 8.48061 15.0975 8.44311 15.149C8.40562 15.2006 8.35662 15.2427 8.3 15.272C8.24526 15.3006 8.18449 15.3156 8.12274 15.316C8.061 15.3163 8.00006 15.3019 7.945 15.274C6.785 14.795 6.149 13.496 6.149 11.834C6.149 8.849 8.64 6.25 12.341 6.25C15.476 6.25 17.822 8.579 17.822 11.39C17.822 14.922 15.89 17.494 13.132 17.494C12.7397 17.5054 12.3502 17.4245 11.9949 17.258C11.6395 17.0914 11.3282 16.8438 11.086 16.535L11.043 16.712L10.836 17.564L10.834 17.571C10.688 18.171 10.586 18.588 10.546 18.745C10.44 19.1 10.306 19.448 10.146 19.785C12.1359 20.2587 14.2316 19.9522 16.0025 18.9285C17.7734 17.9047 19.0849 16.2416 19.6674 14.2808C20.2499 12.32 20.0593 10.2106 19.1346 8.38601C18.2099 6.56146 16.6215 5.16041 14.6957 4.47081C12.77 3.78122 10.6532 3.85547 8.78053 4.67831C6.90784 5.50115 5.42147 7.01005 4.62691 8.89492C3.83235 10.7798 3.78996 12.8974 4.50845 14.8125C5.22694 16.7277 6.65174 18.2949 8.49 19.192V19.191ZM12 22C6.477 22 2 17.523 2 12C2 6.477 6.477 2 12 2C17.523 2 22 6.477 22 12C22 17.523 17.523 22 12 22Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_2648_41199">
          <rect className="w-8 h-8" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default PinterestIcon;
